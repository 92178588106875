import BaseAPI from "../api";

const path = "/aomdown/app/masterdata";

export default class GetPaymenApi extends BaseAPI {
  static Time(): Promise<any> {
    return this.api.get(`${path}/getTimePayment`).then((res) => res);
  }

  static Round(): Promise<any> {
    return this.api.get(`${path}/getRoundPayment`).then((res) => res);
  }
}
