
import BaseAPIUpload from '../api-upload'

const path = '/aomdown/app/aomdown'

export default class AomdownUploadApi extends BaseAPIUpload {

    static uploadSignature(payload: any): Promise<any> {
        return this.api.post(`${path}/uploadSignature`, payload).then((res) => res)
    }

}