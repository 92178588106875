import {
  Typography
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import OtpApi from "../../../../api/otp/otp.api";
import ButtonCustom from "../../../../components/button/buttonCustom";
import InputTextField from "../../../../components/input/inputTextField";
import { colors } from "../../../../constants/colors";
import liff from "@line/liff";
import axios from "axios";
import Loading from "../../../../components/loading/loading";
import {
  swalError
} from "../../../../components/notification/swal";
import { setClientKey, setRefreshToken, setToken } from "../../../../utils/app.utils";
import { encryptString } from '../../../../utils/crypto-js';
import { checkPattenPhoneNumber } from "../../../../utils/common";
import { keyLocalStorage } from "../../../../utils/enum";

interface otp {
  loadCheckStep?: () => void;
  onClickNext?: (e: any) => void;
  dataProduct?: any;
  telCurrentUser: string;
  accessTokenLine: string | null;
  setSetpperPage?: () => void;
}


const initStateErrorMessage = {
  REGISTER_MOBILE: ``,
};

export default function Otp(props: otp) {
  const [loadingPage, setLoadingPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [otp, setOtp] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [confirmOtp, setConfirmOtp] = useState<boolean>(false);
  const [countdown, setCountdown] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [checkPhoneNumber, setCheckPhonenumber] = useState<boolean>(false)
  const [checkDigiPhoneNumber, setCheckDigiPhonenumber] = useState<boolean>(false)

  useEffect(() => {
    if (phoneNumber && phoneNumber.length === 10) {
      const check = checkPattenPhoneNumber(phoneNumber)
      setCheckPhonenumber(!check)
      setCheckDigiPhonenumber(false)
    }
  }, [phoneNumber]);

  useEffect(() => {
    let timer: any;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsButtonDisabled(true);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleChange = (newValue: string) => {
    setOtp(newValue);
    sendVerifyOtpAuto(newValue);
  };

  const sendVerifyOtpAuto = async (numberOtp: string) => {
    const isNumeric = /^\d+$/.test(numberOtp.trim());
    if (!isNumeric) {
      console.log("OTP must contain only numbers.");
      return;
    }
    if (numberOtp.length === 6) {
      if (props.telCurrentUser === '') {
        verifyOtp(numberOtp, true);
      } else {
        if (props.telCurrentUser === phoneNumber) {
          verifyOtp(numberOtp, false);
        } else {
          verifyOtp(numberOtp, true);
        }
      }
    }
  };

  const verifyOtp = async (numberOtp: string, type_create: boolean) => {
    try {
      setLoadingPage(true);
      const encryptPhoneNumber = encryptString(phoneNumber);
      const encryptOtpNumber = encryptString(numberOtp);
      const payload = {
        tel: encryptPhoneNumber,
        otp: encryptOtpNumber,
        product: {
          model: props?.dataProduct.model,
          storage: props?.dataProduct.storage,
          color: props?.dataProduct.color,
        },
        downpayment_info: {
          amount: props?.dataProduct.amount,
          time_payment: props?.dataProduct.time_payment,
          round_payment: props?.dataProduct.round_payment,
          round_amount: props?.dataProduct.round_amount
        },
        type_create: type_create,
        accessTokenLine: props.accessTokenLine,
        // accessTokenLine: "eyJhbGciOiJIUzI1NiJ9.xOTbX5mkF3K7aE9v0Amg7kyhmuwM462FwmEkW3tPY2UpO0cj8cN9RQgKCr5kOBYkfoQ8wR6OX4JQz9CE6MNDoL0not75MVnCHBV9Rli_fV7hXVlR50c6ziW7--RIikVOJ4JjKaMJ08zna9hil59-ppUAZU26M_8M72thzsZY9ag.0LcMmxYdWdKfD3Oz1gyzU0MVlrvG8QYM-YzlymO4fFo",
      };
      const res = await OtpApi.createUser(payload);
      console.log(res);
      if (res.status === 200) {
        if (res.data?.message === "LOGIN") {
          setToken(res.data.data.accessToken)
          setRefreshToken(res.data.data.refreshToken)
          setClientKey(res.data.data.clientKey)
          window.location.reload()
          setTimeout(() => {
            if (props.onClickNext) props.onClickNext(
              {
                token: res.data.data.accessToken,
                refreshToken: res.data.data.refreshToken,
                clientKey: res.data.data.clientKey
              }
            )
          }, 500);
        } else if (res.data.message === "CURRENT") {
          setTimeout(() => {
            if (props.loadCheckStep) props.loadCheckStep()
          }, 500);
        } else if (res.data.message === "NOTPASS-DEPOSIT") {
          swalError("ตรวจพบสัญญา รอพิจรณาที่ค้างอยู่ ไม่สามารถทำรายการได้ ขออภัยในความไม่สะดวกค่ะ");
          liff.closeWindow();
        } else if (res.data.message === "NOTPASS-PRODUCT") {
          swalError("ตรวจพบ สัญญาที่ ข้อมูลยังไม่ครบ กรุณากรอกข้อมูลและทำตามขั้นตอน ให้ครบก่อนนะคะ");
          liff.closeWindow();
        } else if (res?.data?.message === "OTP-NOT-FOUND") {
          swalError("รหัส OTP ไม่ถูกต้อง!!");
        } else if (res?.data?.message === "OTP-OVER-VERIFY") {
          swalError("กรอกรหัส OTP ผิดเกินกำหนด ไม่สามารถทำรายการได้!");
          setConfirmOtp(false);
        }
        setLoadingPage(false);
      }
    } catch (error: any) {
      setLoadingPage(false);
      console.error("verifyOtp:", error);
      swalError(String(error?.response?.data?.message))
    } finally {
      setOtp("");
    }
  };

  const requestOtp = async (e: any) => {
    if (checkPhoneNumber || checkDigiPhoneNumber || phoneNumber.length !== 10) {
      const msg: any = {
        REGISTER_MOBILE: !phoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง' : phoneNumber.length !== 10 ? 'ระบุจำนวน 10 ตัวอักษร' : '',
      }
      return setErrorMessage({ ...errorMessage, ...msg })
    }
    setIsButtonDisabled(true);
    e.preventDefault();
    try {
      const encryptPhoneNumber = encryptString(phoneNumber);
      setLoadingPage(true);
      const res = await OtpApi.otpRequest({ tel: encryptPhoneNumber, page: "register" });
      console.log('res request otp', res);
      if (res.status === 200) {
        if (res.data.message === "OTP-OVER-VERIFY") {
          swalError("กรอกรหัส OTP ผิดเกินกำหนด ไม่สามารถทำรายการได้!");
          setLoadingPage(false);
          return;
        }
        setConfirmOtp(true);
      }
      setLoadingPage(false);
    } catch (error: any) {
      setLoadingPage(false);
      swalError(String(error?.response.data.message));
      setConfirmOtp(false);
      console.error("There was an error making the request:", error);
      setConfirmOtp(false);
      setOtp("");
    }
  };

  // request otp เมื่อกดขอ otp ใหม่
  const requestOtpTimeout = async (e: any) => {
    try {
      setOtp("");
      e.preventDefault();
      const encryptPhoneNumber = encryptString(phoneNumber);
      console.log('encryptPhoneNumber', encryptPhoneNumber);
      const res = await OtpApi.otpRequest({ tel: encryptPhoneNumber, page: "register" });
      if (res.status === 200) {
        setIsButtonDisabled(false);
        setCountdown(30);
      }
    } catch (error: any) {
      swalError(String(error?.response.data.message));
      setIsButtonDisabled(true);
      console.error("There was an error making the request:", error);
    }
  };

  return (
    <div>
      <Loading show={loadingPage}></Loading>
      {!confirmOtp && (
        <>
          {/* <Typography>กรอกเบอร์โทรศัพท์เพื่อรับรหัส OTP</Typography> */}
          <div className="font-20 fw-bold">
            <InputTextField
              type="number"
              value={phoneNumber}
              heading="กรอกเบอร์โทรศัพท์เพื่อรับรหัส OTP"
              size="medium"
              headingColor={colors.warning}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onchange={(e: any) => {
                if (e.target.value.length <= 10) {
                  setPhoneNumber(e.target.value);
                  setErrorMessage({ ...errorMessage, REGISTER_MOBILE: '' })
                }
              }}
              required
              helperText={errorMessage.REGISTER_MOBILE}
            />
          </div>
          <div className="pt-2">
            <ButtonCustom
              mode={"default"}
              btnStyle={{ width: "50%" }}
              textButton={"ส่งรหัส OTP"}
              onClick={(e) => {
                requestOtp(e);
              }}
            />
          </div>
        </>
      )}

      {confirmOtp && (
        <>
          <Typography className="text-center fw-bold font-18" style={{ color: colors.warning, fontSize: '20px' }}>
            รหัสถูกส่งไปที่ SMS บนโทรศัพท์ของท่าน
          </Typography>
          <MuiOtpInput TextFieldsProps={{
            type: 'number',
            inputProps: {
              inputMode: 'numeric',
              pattern: '[0-9]*',
            },
          }} value={otp || ""} length={6} onChange={handleChange} />
          <div className="f-14 request-otp" style={{ marginTop: '5px' }}>
            {isButtonDisabled ? (
              <a className="otp-link" style={{ color: colors.warning }} onClick={(e) => requestOtpTimeout(e)}>
                กดเพื่อส่งรหัส OTP ใหม่อีกครั้ง
              </a>
            ) : (
              <a className="otp-link-enabled">
                กรุณารอทำรายการใหม่อีกครั้งใน {countdown} วินาที
              </a>
            )}
          </div>
        </>
      )}
    </div>
  );
}
