import BaseAPI, {  key } from '../api'

const path = '/aomdown/app/aomdown'

export default class AomdownApi extends BaseAPI {
    static getAomdownDetail(): Promise<any> {
        return this.api.get(`${path}/getAomdownDetail`).then((res) => res)
    }

    static getAdminRegister(): Promise<any> {
        return this.api.get(`${path}/getAdminRegister`).then((res) => res)
    }

    static getAdminRegisterNewToken(token: any): Promise<any> {
        return this.api.get(`${path}/getAdminRegister`, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": key,
                Authorization: token ? `Bearer ${token}` : ``,
            }
        }).then((res) => res)
    }

    static getAomdownPDF(payload: string): Promise<any> {
        return this.api.get(`${path}/${payload}/export`).then((res) => res)
    }

    static updateAcceptConditions(): Promise<any> {
        return this.api.post(`${path}/updateAcceptConditions`).then((res) => res)
    }
    static calculateAomdown(playload: any): Promise<any> {
        return this.api.post(`${path}/calculate/amount/downpayment`, playload).then((res) => res);
    }

    static getTime(playload: any): Promise<any> {
        return this.api.post(`${path}/calculate/month/downpayment`, playload).then((res) => res);
    }

    static checkStep(): Promise<any> {
        return this.api.get(`${path}/checkStep`).then((res) => res);
    }

}
