import { styled } from "@mui/material";
import _ from "lodash";
import ButtonCustom from "../../../../components/button/buttonCustom";
import { colors } from "../../../../constants/colors";
import { onImgError } from "../../../../constants/images";
import { numberFormat } from "../../../../utils/common";

const Container = styled("div")(() => ({
  width: "100%",
  height: "100%",
  border: `1px solid ${colors.lightGray}`,
  borderRadius: 8,
  alignItems: "center",
  padding: 8,
  display: 'inline-grid'
}));

const ImageStyle = styled(`img`)(({ theme }) => ({
  width: 180,
  height: 180,

  [theme.breakpoints.down("lg")]: {
    width: 120,
    height: 120,
  },
}));

const Dot = styled("div")(({ theme }) => ({
  height: 10,
  width: 10,
  borderRadius: "50%",
  display: "inline-block",
  boxShadow: "0 0px 3px rgba(0, 0, 0, 0.5)",
}));

interface cardProdct {
  img?: string;
  name?: string;
  storage?: string;
  color?: any;
  price?: string;
  down?: string;
  onClick?: () => void;
}

export default function CardProduct(props: cardProdct) {
  return (
    <>
      <Container>
        <div className="text-center">
          <ImageStyle src={props?.img} onError={onImgError} />
        </div>
        <div className="pt-3 fw-bold font-18">{props?.name || ""}</div>
        <div className="font-10" style={{ color: colors.gray, marginTop: "-5px" }}>
          {props.storage || ""}
        </div>

        <div className="d-flex justify-content-start" style={{ marginTop: "-10px" }}>
          <div className="pt-1 font-10" style={{ marginTop: "6px", color: colors.themeMainColor }}>
            {"ราคาเริ่มต้น"}
          </div>
          <div className="pt-1 ml-2" style={{ marginLeft: "7px" }}>
            <span className="pl-2 fw-bold font-12">
              {`${numberFormat(props?.price, 0, 0)} บาท` || "0 บาท"}
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-start" style={{ marginTop: "-14px" }}>
          <div className="pt-1 font-10" style={{ marginTop: "6px", color: colors.themeMainColor }}>
            {"ดาวน์เริ่มต้น"}
          </div>
          <div className="pt-1 ml-2" style={{ marginLeft: "7px" }}>
            <span className="pl-4 fw-bold font-10" >
              {`${numberFormat(props?.down, 0, 0)} บาท` || "0 บาท"}
            </span>
          </div>
        </div>

        <div>
          <div className="d-flex" style={{ border: "none" }}>
            {_.map(props.color, (e: any, index: number) => {
              return (
                <div key={index} style={{ paddingRight: 4 }}>
                  <Dot sx={{ backgroundColor: e.colorCode || "" }} />
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <ButtonCustom
            btnStyle={{ width: "100%" }}
            textButton={"เลือก"}
            onClick={() => {
              props.onClick && props.onClick();
            }}
          />
        </div>
      </Container>
    </>
  );
}
