import BaseAPIUpload from '../api-upload'

const path = '/payment/app/'

export default class PaymentUploadApi extends BaseAPIUpload {

    static paymentUploadSlipFile(payload: any): Promise<any> {
        return this.api.post(`${path}/downpayment/aom-with-slip`, payload).then((res) => res)
    }

}