import BaseAPI from '../api'

const path = '/aomdown/app/personalDetail'

interface PersonalDetail {
    name: string;
    surname: string;
    idCard: string;
    promoCode?: string;
}

export default class PersonalDetailApi extends BaseAPI {

  static updatePersonalDetail(payload: PersonalDetail): Promise<any> {
    return this.api.post(`${path}/updatePersonalDetail`, payload).then((res) => res)
  }

}
