import { styled } from "@mui/material";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import MasterDataApi from "../../../api/masterdata/masterdata.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import Loading from "../../../components/loading/loading";
import { swalError } from "../../../components/notification/swal";
import CustomRadioBox from "../../../components/radioLabel/radioLabel";
import { colors } from "../../../constants/colors";

const Container = styled("div")(() => ({
    width: "100%",
    height: "600px",
    border: `1px solid ${colors.themeMainColor}`,
    borderRadius: 8,
    alignItems: "center",
    // display: "flex",
    flexDirection: "column",
    padding: 8,
    overflow: 'auto'
}));

interface AccpteCondition {
    onClickNext?: () => void;
}

export default function AccpteCondition(props: AccpteCondition) {
    const [disable, setDisable] = useState(true);
    const [dataCondtion, setDataCondition] = useState('')
    const [radioAceept, setRadioAceept] = useState(true)
    const containerRef = useRef<HTMLDivElement>(null);
    const [loadingPage, setLoadingPage] = useState(false);

    useEffect(() => {
        getTermsAndConditions();
    }, []);

    useEffect(() => {
        if (containerRef.current) {

            // Check if the container's height is enough to fit all content
            const containerHeight = containerRef.current.clientHeight;
            const isScrollable = containerRef.current.scrollHeight - containerRef.current.scrollTop == (containerHeight / 2)
            isScrollable ? setDisable(false) : setDisable(true);
        }
    }, [dataCondtion]); // Run this effect after dataCondtion is updated


    const handleScroll = (e: any) => {
        const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) == (e.target.clientHeight / 2);
        setDisable(false)
    };

    const getTermsAndConditions = async () => {
        try {
            setLoadingPage(true);
            const res = await MasterDataApi.getTermsAndConditions();
            if (res.status == 200) {
                setLoadingPage(false)
                const termsAndConditions = res?.data?.data
                if (termsAndConditions) setDataCondition(termsAndConditions)

            } else {
                setLoadingPage(false)
                swalError(String(res?.data?.message));
            }
        } catch (error: any) {
            swalError(String('เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
            setLoadingPage(false)
            console.error(error)
        }
    }

    const updateAcceptConditions = async () => {
        try {
            setLoadingPage(true);
            const res = await AomdownApi.updateAcceptConditions();
            if (res.status == 200) {
                setLoadingPage(false);
                console.log('AcceptConditionsApi success')
                props.onClickNext && props.onClickNext();
            }
            props.onClickNext && props.onClickNext();
        } catch (error) {
            setLoadingPage(false);
            console.error(error)
        }
    }

    return (
        <div>
            <Loading show={loadingPage} />
            <p className="font-bold font-20" style={{ color: colors.orange }}>ข้อตกลงและเงื่อนไข</p>
            <Container ref={containerRef} onScroll={handleScroll}>
                {_.map(dataCondtion, (val: any) => {
                    return (
                        <div className="py-2">
                            <span className="font-bold pr-1">{val?.no || ''}</span>
                            <span>{val?.text || ''}</span>
                        </div>
                    )
                })}
            </Container>
            <div className="pt-2">
                <CustomRadioBox
                    disabled={disable}
                    label={'ข้าพเจ้ายอมรับข้อกำหนดและเงื่อนไขของ ShibaPhone'}
                    onChange={(e) => {
                        if (e.target.checked) setRadioAceept(false);
                    }} />
            </div>
            <div className="pt-4">
                <ButtonCustom
                    mode={'default'}
                    btnStyle={{ width: '100%' }}
                    textButton={'ยอมรับข้อตกลงและเงื่อนไข'}
                    disabled={disable || radioAceept}
                    onClick={() => {
                        updateAcceptConditions();
                    }}
                />
            </div>
        </div>
    )
}