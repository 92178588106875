import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { Row } from 'react-bootstrap';
import HelperText from '../input/helperText';
import { colors } from '../../constants/colors';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useRef } from 'react';
import { LinearProgress } from '@mui/material';
import _ from 'lodash';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


type UploadImageProps = {
  onUpload?: (event: any) => void
  helperText?: any;
  loading?: boolean | false
  ref?: any
  preview?: any
}

export default function UploadImage(props: UploadImageProps) {
  return (
    <>
      {_.isEmpty(props.preview) && props.loading == false && (
        <>
          <div>
            <CloudUploadOutlinedIcon sx={{ width: 100, height: 100, color: colors.themeMainColor, background: colors.lightGray, padding: 3, borderRadius: '50%' }} />
          </div>
          <LoadingButton
            component="label"
            role={undefined}
            variant="text"
            tabIndex={-1}
            sx={{ color: colors.themeMainColor }}
          >
            <div>
              อัพโหลดสลิปการชำระเงิน
            </div>
            <VisuallyHiddenInput ref={props.ref} onClick={(event) => {
              (event.target as HTMLInputElement).value = '';
            }}
              type="file" accept="image/png, image/jpeg , image/jpg" onChange={(event: any) => { props.onUpload && props.onUpload(event) }} />

          </LoadingButton>
        </>
      )}
      {props.loading == true && (
        <LinearProgress sx={{ color: colors.themeMainColor }} />
      )}
      {!_.isEmpty(props.preview) && (
        <>
          <div className='pb-2' style={{ borderRadius: '10%' }}>
            <img src={`${props.preview}`} width="300" height="350" style={{ borderRadius: '10px'}}/>
          </div>
          <div>
            <LoadingButton
              component="label"
              role={undefined}
              variant="text"
              tabIndex={-1}
              sx={{ color: colors.themeMainColor }}
            >
              <div>
                อัพโหลดใหม่
              </div>
              <VisuallyHiddenInput ref={props.ref} onClick={(event) => {
                (event.target as HTMLInputElement).value = '';
              }}
                type="file" accept="image/png, image/jpeg , image/jpg" onChange={(event: any) => { props.onUpload && props.onUpload(event) }} />

            </LoadingButton>
          </div>
        </>
      )}

      {props.helperText && (
        <div className='pt-2 text-center'>
          <HelperText label={props.helperText} />
        </div>
      )}
    </>
  )
}
