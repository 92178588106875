import liff from "@line/liff";
import axios from "axios";
import { useEffect } from "react";
import { logout } from "../../utils/app.utils";
import { imgs } from "../../constants/images";

export default function Logout() {
    useEffect(() => {
        liff.init({ liffId: '2005464105-b3kgd3rD', withLoginOnExternalBrowser: true })
            .then(async () => {
                if (liff.isLoggedIn()) {
                    const token = await liff.getProfile()
                    await axios.post('https://open-api.chatcone.com/v3/verify/logout', {}, {
                        headers: {
                            'Content-Type': 'application/json',
                            api_key: '2d4d04975e92df9ea38d297da506e138d5205b64025a96b730cca465d440ead3',
                            channel_key: "52e207319ad4dea7849e64bb340619be",
                            line_id: token.userId
                        }
                    });
                    logout()
                    liff.closeWindow()
                } else {
                    liff.login();
                    logout()
                    liff.closeWindow()
                }
            })
            .catch((err) => {
                logout()
                liff.closeWindow()
            })
    }, []);

    return (
        <div style={{}}>
            <img
                width={390}
                height={291}
                src={imgs.logOut}
            />
        </div>
    )
}