import liff from "@line/liff";
import { Typography } from "@mui/material";
import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import AomdownUploadApi from "../../../../api/aomdown/aomdownUpload.api";
import ButtonCustom from "../../../../components/button/buttonCustom";
import Loading from "../../../../components/loading/loading";
import { swalError, swalSuccess } from "../../../../components/notification/swal";
import { colors } from "../../../../constants/colors";
import AuthenApi from "../../../../api/authen/authen.api";

interface props {
    accTokenLine: string
}
export default function SignContact(props: props) {
    const singpad = useRef<SignatureCanvas>(null);
    const [signatureDataURL, setSignatureDataURL] = useState<any>();
    const [loadingPage, setLoadingPage] = useState(false);

    const dataURItoBlob = (dataURI: string) => {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    const handleEnd = () => {
        if (singpad.current) {
            const dataUrl = singpad.current.toDataURL(); // Get base64 data URL
            const data = dataURItoBlob(dataUrl);

            setSignatureDataURL(data);
        }
    };

    const closePage = async () => {
        await AuthenApi.verify(props.accTokenLine || '')
        liff.closeWindow();
    }

    const uploadImg = async (signatureBlob: Blob) => {
        if (signatureBlob) {
            // สร้าง FormData
            const formData = new FormData();
            formData.append('file', signatureBlob);
            formData.append('accessTokenLine', props.accTokenLine);

            try {
                // เรียกใช้ API อัปโหลดไฟล์
                setLoadingPage(true);
                const response = await AomdownUploadApi.uploadSignature(formData);
                if (response.status == 200) {
                    setLoadingPage(false);
                    swalSuccess(
                        "ลงทะเบียนออมดาวน์สำเร็จ",
                        '',
                        null,
                        closePage
                    );

                }
            } catch (error) {
                swalError(String('เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
                setLoadingPage(false);
                console.error('Upload failed:', error);
            }
        } else {
            setLoadingPage(false);
            console.log('No signature to upload');
        }
    }

    const handleClear = () => {
        if (singpad.current) {
            singpad.current.clear();
            setSignatureDataURL("");
        }
    };

    return (
        <div>
            <Loading show={loadingPage} />
            <div className="pb-2">
                <p className="font-bold font-20" style={{ color: colors.orange }}>
                    กรุณาลงลายมือชื่อ
                </p>
            </div>
            <div className="d-flex justify-content-end pb-2">
                <ButtonCustom
                    btnStyle={{ width: '10%' }}
                    textButton={'ล้าง'}
                    onClick={() => {
                        handleClear()
                    }}
                />
            </div>
            <div className="pt-2"
                style={{
                    display: "flex",
                    width: "100%",
                    height: "250px",
                    border: `1px solid${colors.themeMainColor}`
                }}>
                <SignatureCanvas
                    backgroundColor="#ffff"
                    canvasProps={{ className: "sigCanvas" }}
                    ref={singpad}
                    onEnd={handleEnd}
                />
            </div>
            <div className="pt-2">
                <ButtonCustom
                    mode={'default'}
                    btnStyle={{ width: '100%' }}
                    textButton={'ยืนยัน'}
                    disabled={!signatureDataURL}
                    onClick={() => {
                        console.log('signatureDataURL', signatureDataURL);
                        uploadImg(signatureDataURL);
                        handleClear()
                    }}
                />
            </div>
        </div>
    )
}