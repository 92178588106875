import { FormControlLabel, Radio, RadioProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface CustomRadioBoxProps extends RadioProps {
    label?: string;
  }

const CustomRadio = styled(Radio)(() => ({
    color: '#F9AFA2', // Default color for unchecked state
    '&.Mui-checked': {
        color: '#F9AFA2  !important', // Color when checked
    },
}));

export default function RadioLabel(props: CustomRadioBoxProps) {
    return (
        <FormControlLabel
            control={
                <CustomRadio
                    {...props}
                />
        } 
        label={props.label} />

    )
}