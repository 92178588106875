import liff from "@line/liff";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import AuthenApi from '../../../api/authen/authen.api';
import MasterDataApi from "../../../api/masterdata/masterdata.api";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import InputTextField from "../../../components/input/inputTextField";
import { swalError } from "../../../components/notification/swal";
import { imgs } from "../../../constants/images";
import { routeName } from "../../../routes/routers-name";
import { getToken } from "../../../utils/app.utils";
import { decryptString } from "../../../utils/crypto-js";
import AccpteCondition from "../accpet-condition/accpte-condtion";
import CardProductDetail from "../componant/card-product-detail/card-product-detail";
import CardProduct from "../componant/card-product/card-product";
import Otp from "../componant/otp/otp";
import PersonalDetail from "../componant/personal-detail/personal-detail";
import SignContact from "../componant/sign-contact/sign-contact";
import { colors } from "../../../constants/colors";

// step page
// 0 = หน้า แสดง product และเลือกข้อมูล ออมดาว
// 1 = หน้า otp
// 2 = หน้า กรอกชื่อลงทะเบียน
// 3 = หน้า ยอมรับเงื่อนไข
// 4 = หน้า เซ็นสัญญา
// 5 = หน้า หน้า อัพโหลด สลิป

export default function RegisterCustomer() {
  const [data, setData] = useState<any[]>([]);
  const [selectProduct, setSelectProduct] = useState<any>({});
  const [search, setSearch] = useState<string>("");
  const [setpper, setSetpper] = useState<number>(0);
  const history = useHistory();
  const [telCurrentUser, setTelCurrentUser] = useState<any>("");
  const [accessTokenLine, setAccessTokenLine] = useState<string | null>("");
  const [anyToken, setAnyToken] = useState<string | null>("");
  const token = getToken()
  const location: any = useLocation()
  const checkLogin: any = location?.state?.params?.state
  const loadCurrentUser = async () => {
    const result = await AuthenApi.getUser();
    if (result.status === 200 && result?.data?.data) {
      const tel = result.data?.data?.getCurrent?.tel
      const decryptTel = decryptString(tel);
      setTelCurrentUser(decryptTel); // tel
    }
  }

  const loadCheckStep = async () => {
    const res = await AomdownApi.checkStep();
    const data = res.data.data;
    if (res.status === 200 && data !== null) {
      if (data?.setpper === 5) {
        history.push(routeName.paySlip);
      } else {
        setSetpper(data?.setpper)
      }
    }
  };

  const loadProduct = useCallback(() => {
    let condition: any = {};
    if (search) condition = { ...condition, productName: search };
    MasterDataApi.getProduct(condition).then((res) => {
      if (res.status === 200) {
        setData(res.data.data.result);
      }
    });
  }, [search]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct])

  useEffect(() => {
    if (token) {
      loadCheckStep();
      loadCurrentUser();
    }
  }, []);

  useEffect(() => {
    if (!checkLogin) {
      liff.init({ liffId: '2005464105-aeGbJ9zm', withLoginOnExternalBrowser: true })
        .then(async () => {
          if (liff.isLoggedIn()) {
            const accessTokenLine = await liff.getAccessToken()
            setAccessTokenLine(accessTokenLine)
          } else {
            liff.login();
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [checkLogin]);

  return (
    <div className="px-3 py-3">
      <Row className="pt-2 ">
        {_.isEmpty(selectProduct) && setpper < 1 && (
          <>
            <Col lg={6} xs={12} sm={12}>
              <div>
                <InputTextField
                  label="ค้นหารุ่นสินค้า"
                  value={search}
                  onchange={(e: any) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col
              lg={6}
              xs={6}
              sm={12}
              className="pt-2 w-100"
              style={{ textAlign: "center" }}
            >
              <img src={imgs.banner} width={"100%"} />
            </Col>
            <>
              {_.map(data, (e: any, index: number) => {
                return (
                  <Col key={index} lg={6} xs={6} sm={6} className="pt-3">
                    <CardProduct
                      key="CardProductKey"
                      img={e.image}
                      name={e.pname}
                      storage={e.storage}
                      color={e.option[0].color}
                      price={e.price}
                      down={e.down}
                      onClick={() => {
                        setSelectProduct(e.code);
                      }}
                    />
                  </Col>
                );
              })}
            </>
          </>
        )}
        {!_.isEmpty(selectProduct) && setpper < 1 && (
          <div>
            <CardProductDetail
              key="CardProductDetailKey"
              code={selectProduct}
              onClickBack={() => setSelectProduct({})}
              onClickNext={(e: any) => {
                setSelectProduct(e);
                setSetpper(1);
              }}
            />
          </div>
        )}
        {setpper == 1 && (
          <Otp
            dataProduct={selectProduct}
            telCurrentUser={telCurrentUser}
            loadCheckStep={() => loadCheckStep()}
            accessTokenLine={accessTokenLine}
            onClickNext={(e: any) => {
              setAnyToken(e)
              setSetpper(2);
            }}
          />
        )}
        {setpper == 2 &&
          <PersonalDetail
            onClickNext={() => {
              setSetpper(3);
            }}
          />}
        {setpper == 3 &&
          <AccpteCondition
            onClickNext={() => {
              setSetpper(4);
            }}
          />}
        {setpper == 4 && <SignContact accTokenLine={accessTokenLine || ''} />}
      </Row>
    </div>
  );
}
