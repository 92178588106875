import axios from "axios";
import { getClientKey, getToken } from "../utils/app.utils";

export const apiUrl = process.env.REACT_APP_API;
export const key = process.env.REACT_APP_X_API_KEY;

const clientKey = getClientKey();

const token = getToken();
const instance = axios.create({
  baseURL: apiUrl || process.env.REACT_APP_API,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": key,
    "client-key": clientKey,
    Authorization: token ? `Bearer ${token}` : "",
  },
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log(error);
  }
);

export default class BaseAPI {
  static api = instance;
}

