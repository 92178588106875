import { defaultMenu } from "../constants/menu";
import Login from "../pages/aomdown/login/login";
import RegisterCustomer from "../pages/aomdown/register-customer/register-customer";
import Register from "../pages/register/register";
import UploadSlip from "../pages/upload-slip/upload-slip";
import AomdownDetail from "../pages/aomdown/componant/aomdown-detail/aomdown-detail";
import { routeName } from "./routers-name";
import Logout from "../pages/logout/logout";

const routes = [
  {
    key: defaultMenu.REGISTER.key,
    name: defaultMenu.REGISTER.name,
    textName: defaultMenu.REGISTER.textName,
    component: Register,
    path: routeName.register,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.REGISTER_AOM_DOWN.key,
    name: defaultMenu.REGISTER_AOM_DOWN.name,
    textName: defaultMenu.REGISTER_AOM_DOWN.textName,
    component: RegisterCustomer,
    path: routeName.registerAomDown,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.AOM_DOWN_DETAIL.key,
    name: defaultMenu.AOM_DOWN_DETAIL.name,
    textName: defaultMenu.AOM_DOWN_DETAIL.textName,
    component: AomdownDetail,
    path: routeName.aomDownDetail,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.UPLOAD_SLIP.key,
    name: defaultMenu.UPLOAD_SLIP.name,
    textName: defaultMenu.UPLOAD_SLIP.textName,
    component: UploadSlip,
    path: routeName.paySlip,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.LOGIN.key,
    name: defaultMenu.LOGIN.name,
    textName: defaultMenu.LOGIN.textName,
    component: Login,
    path: routeName.login,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.LOGOUT.key,
    name: defaultMenu.LOGOUT.name,
    textName: defaultMenu.LOGOUT.textName,
    component: Logout,
    path: routeName.logout,
    header: false,
    footer: false,
  },
];

export default routes;
