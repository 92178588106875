import { styled, ToggleButtonGroup } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AomdownApi from "../../../../api/aomdown/aomdown.api";
import GetPaymenApi from "../../../../api/card-product-detail/card-product-detail-api";
import MasterDataApi from "../../../../api/masterdata/masterdata.api";
import ButtonCustom from "../../../../components/button/buttonCustom";
import InputTextField from "../../../../components/input/inputTextField";
import { swalError } from "../../../../components/notification/swal";
import AutocompleteSelect from "../../../../components/select/autoCompleteSelect";
import { colors } from "../../../../constants/colors";
import { onImgError } from "../../../../constants/images";
import { numberFormat } from "../../../../utils/common";
import { BorderColor } from "@mui/icons-material";

interface productDetail {
  code?: string;
  onClickBack?: () => void;
  onClickNext?: (e: any) => void;
}

const DotSelect = styled("div")(({ theme }) => ({
  zIndex: 1,
  position: "absolute",
  height: 38,
  width: 38,
  borderRadius: "50%",
  display: "inline-block",
  margin: -3.5,
  marginLeft: -4
}));

const Dot = styled("div")(({ theme }) => ({
  zIndex: 10,
  position: "relative",
  height: 30,
  width: 30,
  borderRadius: "50%",
  display: "inline-block",
  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.6)",
}));

const initStateMessaageError = {
  ROUND: ``,
  TIME: ``
}

export default function CardProductDetail(props: productDetail) {
  const [data, setData] = useState<any>([]);
  const [selectedStorage, setSelectedStorage] = useState<string>("");
  const [selectColor, setSelectColor] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [down, setDown] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [dataTimePayment, setDataTimePayment] = useState<any>([]);
  const [dataRoundPayment, setDataRoundPayment] = useState<any>([]);
  const [selectRoundPayment, setSelectRoundPayment] = useState<string>("");
  const [selectTimePayment, setSelectTimePayment] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState(initStateMessaageError)
  const [roundAomdownPrice, setRoundAomdownPrice] = useState<number>(0);

  const loadProductDetail = useCallback(() => {
    try {
      let condition: any = {};
      if (props.code) condition = { ...condition, code: props.code };
      MasterDataApi.getProductDetail(condition).then((res: any) => {
        if (res.status === 200) {
          const val = res.data?.data?.result;
          setData(val);
          setSelectedStorage(val[0]?.option[0]?.storage || "");
          setSelectColor(val[0]?.option[0]?.color[0]?.colorName || "");
          setPrice(val[0]?.option[0]?.color[0]?.price || "");
          setDown(val[0]?.option[0]?.color[0]?.down || "");
          setImage(val[0]?.option[0]?.color[0]?.image || "");
        }
      });
    } catch (error) {
      console.log('product detail ', error);
      swalError('product detail ' + error);
    }
  }, [props.code]);

  const loadTimePayment = async () => {
    try {
      const dataGet = {
        product: {
          color: selectColor,
          model: data[0]?.pname,
          storage: selectedStorage
        },
        type: "INSTALLMENT"
      }
      const result = await AomdownApi.getTime(dataGet);
      if (result.status === 200) {
        const dataMap = await result.data.data.time_payment.name.map(
          (item: any) => {
            return {
              value: item,
              label: String(item),
            };
          }
        );
        setDataTimePayment(dataMap);
      }
    } catch (error) {
      console.log('time payment ', error);
      swalError('time payment ' + error);
    }
  };

  const loadRoundPayment = async () => {
    try {
      const result = await GetPaymenApi.Round();
      if (result.status === 200) {
        const dataMap = await result.data.data.round_payment_data.map(
          (item: any) => {
            return {
              value: item.code,
              label: item.name,
            };
          }
        );
        setDataRoundPayment(dataMap);
      }
    } catch (error) {
      console.log('round payment ', error);
      swalError('round payment ' + error);
    }
  };

  useEffect(() => {
    loadTimePayment();
    loadRoundPayment();
  }, [selectedStorage]);

  useEffect(() => {
    loadProductDetail();
  }, [loadProductDetail]);

  useEffect(() => {
    if (selectColor && selectedStorage && selectTimePayment) {
      handelCalculate();
    }
  }, [selectColor, selectedStorage, selectTimePayment]);

  const handelCalculate = async () => {
    try {
      const playload = {
        type: "INSTALLMENT", // INSTALLMENT
        round_payment: selectRoundPayment, // รอบการออม
        max_month: selectTimePayment, // ระยะเวลาการออม
        product: {
          model: data[0]?.pname, // iPhone 11,
          storage: selectedStorage, // 128GB
          color: selectColor, // BLACK
        },
      };

      const result = await AomdownApi.calculateAomdown(playload);
      if (result.status === 200) {
        setRoundAomdownPrice(result.data.data.round_amount);
      }
    } catch (error) {
      console.log("Calculate Error : ", error);
      swalError("Calculate Error : " + error);
    }
  };

  const handelSubmit = () => {
    if (selectTimePayment === 0 || selectRoundPayment === '') {
      const msg: any = {
        ROUND: selectRoundPayment === '' ? 'โปรดเลือกรายการออม' : '',
        TIME: selectTimePayment === 0 ? 'โปรดเลือกระยะเวลาการออม (เดือน)' : ''
      }
      return setErrorMessage({ ...initStateMessaageError, ...msg })
    }

    const selectData: any = {
      model: data[0]?.pname, // object ของ product
      storage: selectedStorage, // object ของ product
      color: selectColor, // object ของ product
      amount: Number(down) || 0, // object ของ downpayment_info
      time_payment: selectTimePayment, // object ของ downpayment_info
      round_payment: selectRoundPayment, // object ของ downpayment_info
      round_amount: roundAomdownPrice, // object ของ downpayment_info
    };
    props.onClickNext && props.onClickNext(selectData); // ส่งค่ากลับไปยังต้นทาง พร้อมเปลี่ยน step
  };

  const changeStorage = (e: any) => {
    setSelectedStorage(e.storage);
    setSelectColor(e.color[0]?.colorName || "");
    setPrice(e.color[0]?.price || "");
    setDown(e.color[0]?.down || "");
    setImage(e.color[0]?.image || "");
    setSelectRoundPayment('');
    setSelectTimePayment(0);
    setErrorMessage(initStateMessaageError)
    setRoundAomdownPrice(0)
  }

  const changeColor = (a: any) => {
    setSelectColor(a.colorName || "");
    setPrice(a.price || "");
    setDown(a.down || "");
    setImage(a.image || "");
    setSelectRoundPayment('');
    setSelectTimePayment(0);
    setErrorMessage(initStateMessaageError)
    setRoundAomdownPrice(0)
  }


  return (
    <>
      {data && (
        <>
          <div className="text-center">
            <img height={300} width={300} src={image} onError={onImgError} />
          </div>
          <div className="pt-2 fw-bold font-20">{data[0]?.pname || ""}</div>
          <div className="d-flex justify-content-start">
            <div className="pt-2 font-16" style={{ marginTop: "3px", color: colors.themeMainColor }}>
              {"ราคาเริ่มต้น"}
            </div>
            <div className="pt-2 ml-2" style={{ marginLeft: "10px" }}>
              <span className="pl-2 fw-bold font-20">
                {`${numberFormat(price, 0, 0)} บาท` || "0 บาท"}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-start" style={{ marginTop: "-10px" }}>
            <div className="pt-2 font-14" style={{ marginTop: "3px", color: colors.themeMainColor }}>
              {"ดาวน์เริ่มต้น"}
            </div>
            <div className="pt-2 ml-2" style={{ marginLeft: "10px" }}>
              <span className="pl-4 fw-bold font-18" >
                {`${numberFormat(down, 0, 0)} บาท` || "0 บาท"}
              </span>
            </div>
          </div>

          <div className="pt-2 fw-bold">{"เลือกความจุ"}</div>
          <div className="pt-1" style={{ marginLeft: '-8px' }}>
            <ToggleButtonGroup>
              {_.map(data[0]?.option, (e: any, index: number) => {
                return (
                  <div style={{ paddingLeft: 8 }} key={`${e.storage}-${index}`}>
                    <ButtonCustom
                      mode={"default"}
                      btnStyle={{ width: "100%" }}
                      textButton={e.storage}
                      variant={
                        selectedStorage === e.storage ? "contained" : "outlined"
                      }
                      onClick={() => {
                        {
                          changeStorage(e)
                        }
                      }}
                    />
                  </div>
                );
              })}
            </ToggleButtonGroup>
          </div>
          <div className="pt-3 fw-bold">{"เลือกสี"}</div>
          <div className="pt-2">
            <div className="d-flex justify-content-start align-items-center">
              {_.map(data[0]?.option, (val: any) => {
                return (
                  val.storage === selectedStorage && (
                    _.map(val.color, (a: any) => {
                      return (
                        a.colorName !== "None" && (
                          <div
                            style={{ marginLeft: "10px" }}
                            key={a.colorName || Math.random()}
                            onClick={() => {
                              changeColor(a)
                            }}
                          >
                            {selectColor === a.colorName &&
                              <DotSelect sx={{ backgroundColor: colors.themeMainColor }} />
                            }
                            <Dot sx={{ backgroundColor: a.colorCode || "" }} />
                          </div>
                        )
                      )
                    })
                  )
                )
              })}
            </div>
          </div>
          <div className="pt-2">
            <AutocompleteSelect
              key={"roundPayment"}
              size="medium"
              options={dataRoundPayment}
              value={_.find(dataRoundPayment, (val: any) => val.value === selectRoundPayment) || null}
              getOptionLabel={(option: any) => option.label}
              labelId="roundPayment"
              onChange={(event, value, reason) => {
                if (value) {
                  setSelectRoundPayment(value.value);
                  setSelectTimePayment(0);
                  setRoundAomdownPrice(0)
                  setErrorMessage({ ...errorMessage, ROUND: '' });
                } else if (reason === 'clear') {
                  setSelectRoundPayment('');
                  setSelectTimePayment(0);
                  setRoundAomdownPrice(0)
                }
              }}
              required
              heading="รอบการออม"
              headingColor={colors.warning}
              helperText={errorMessage.ROUND}
            />
          </div>
          <div className="pt-2">
            <AutocompleteSelect
              size="medium"
              key={"timePayment"}
              options={dataTimePayment}
              value={_.find(dataTimePayment, (val: any) => val.value === selectTimePayment) || null}
              getOptionLabel={(option: any) => option.label}
              labelId="timePayment"
              onChange={(event, value, reason) => {
                if (value) {
                  setSelectTimePayment(value.value);
                  setErrorMessage({ ...errorMessage, TIME: '' });
                } else if (reason === 'clear') {
                  setSelectTimePayment(0);
                  setRoundAomdownPrice(0)
                }
              }}
              required
              heading="ระยะเวลาการออม (เดือน)"
              headingColor={colors.warning}
              helperText={errorMessage.TIME}
            />
          </div>
          <div className="pt-2">
            <InputTextField
              style={{ backgroundColor: colors.warning, borderRadius: "10px" }}
              value={`${numberFormat(roundAomdownPrice, 0, 0)}`}
              heading="ยอดออมต่อรอบ"
              disabled={true}
              headingColor={colors.warning}
            />
          </div>
          <Row className="pt-4">
            <Col>
              <ButtonCustom
                className={"px-1"}
                mode={"default"}
                btnStyle={{ width: "100%" }}
                textButton={"ย้อนกลับ"}
                onClick={() => {
                  props.onClickBack?.();
                }}
              />
            </Col>
            <Col>
              <ButtonCustom
                // style={{ borderRadius: "10%" }}
                className={"px-1"}
                mode={"default"}
                btnStyle={{ width: "100%" }}
                textButton={"ตกลง"}
                onClick={() => {
                  handelSubmit();
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
