import {
  ButtonGroup,
  styled,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { onImgError } from "../../../../constants/images";
import { colors } from "../../../../constants/colors";
import { changeLastname, checkEngLang, checkThaiLang, chkDigitPid, numberFormat } from "../../../../utils/common";
import ButtonCustom from "../../../../components/button/buttonCustom";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MuiOtpInput } from "mui-one-time-password-input";
import InputTextField from "../../../../components/input/inputTextField";
import PersonalDetailApi from "../../../../api/personal-detail/personalDetail.api";
import { swalError, swalSuccess } from "../../../../components/notification/swal";
import Loading from "../../../../components/loading/loading";
import { decryptString, encryptString } from "../../../../utils/crypto-js";
import AomdownApi from "../../../../api/aomdown/aomdown.api";

interface PersonalDetail {
  onClickNext?: () => void;
}

const initStateErrorMessage = {
  NAME: ``,
  SURNAME: ``,
  IDCARD: ``,
};

const ImageStyle = styled(`img`)(({ theme }) => ({
  width: 180,
  height: 180,

  [theme.breakpoints.down("lg")]: {
    width: 150,
    height: 150,
  },
}));

export default function PersonalDetail(props: PersonalDetail) {
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [promotion, setPromotion] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [loadingPage, setLoadingPage] = useState(false);
  const [checkIdCard, setCheckIdCard] = useState<boolean>(false)

  const [adminRegister, setAdminRegister] = useState(false);
  const [data, setData] = useState<any>()

  useEffect(() => {
    if (idCard && idCard.length === 13) {
      const check = chkDigitPid(idCard)
      if (!check) {
        setCheckIdCard(true)
        const msg: any = {
          IDCARDd: !check ? 'กรอกรหัสบัตรประชาชนไม่ถูกต้อง' : '',
        }
        setErrorMessage({ ...errorMessage, ...msg })
      } else {
        setCheckIdCard(false)
      }
    }
  }, [idCard]);

  const handleNextStep = async () => {
    const handleErrorMessage = {
      NAME: !checkThaiLang(name) ? "โปรดกรอกชื่อ" : "",
      SURNAME: !checkThaiLang(surname) ? "โปรดกรอกนามสกุล" : "",
      IDCARD: !idCard ? 'โปรดกรอกรหัสบัตรประชาชน' : idCard.length < 13 ? 'โปรดกรอกรหัสบัตรประชาชนให้ครบ 13 หลัก' : checkIdCard ? 'กรอกรหัสบัตรประชาชนไม่ถูกต้อง' : '',
    };
    setErrorMessage({ ...initStateErrorMessage, ...handleErrorMessage });

    if (!handleErrorMessage.IDCARD && !handleErrorMessage.NAME && !handleErrorMessage.SURNAME) {
      try {
        setLoadingPage(true);
        const lastName = changeLastname(surname);
        const payload = {
          name: encryptString(name.trim()),
          surname: encryptString(lastName),
          idCard: encryptString(idCard.trim()),
          promoCode: promotion.trim(),
        };
        const res = await PersonalDetailApi.updatePersonalDetail(payload);
        if (res.status === 200) {
          setLoadingPage(false);
          props.onClickNext && props.onClickNext();
        } else {
          setLoadingPage(false)
          swalError(String(res?.data?.message));
        }
        props.onClickNext && props.onClickNext();
      } catch (error: any) {
        console.log(error.response.data.data);
        swalError(String(error.response.data.data));
        setLoadingPage(false);
      }
    }
  };

  const checkAdminRegister = async () => {
    setLoadingPage(true);
    const res = await AomdownApi.getAdminRegister();
    if (res.status === 200) {
      const data = res.data.data;
      if (data) {
        setAdminRegister(true)
        const fdata = {
          name: decryptString(data?.user_info?.general?.fullname),
          idCard: decryptString(data?.id_card),
          product_name: data?.product?.text,
          round_payment: data?.downpayment_info?.round_payment,
          time_payment: data?.downpayment_info?.time_payment,
          round_amount: data?.downpayment_info?.round_amount,
          product_img: data?.product_img
        }
        setData(fdata)
      }
      setLoadingPage(false);
    } else {
      setLoadingPage(false);
    }
  };

  const formatRoundPayment = (key: string) => {
    switch (key) {
      case 'DAY':
        return 'รายวัน'
      case 'WEEK':
        return 'รายสัปดาห์'
      case 'MONTH':
        return 'รายเดือน'
      default:
        break;
    }
  }

  useEffect(() => {
    checkAdminRegister();
  }, [])

  return (
    <div>
      <Loading show={loadingPage} />
      {(adminRegister) ? (
        <div>
          <div className="pt-2">
            <InputTextField
              disabled
              value={`${data?.name}`}
              heading="ชื่อ-นามสกุล"
            />
          </div>
          <div className="pt-2">
            <InputTextField
              disabled
              value={data?.idCard}
              heading="เลขบัตรประชาชน"
            />
          </div>
          <div className="pt-2">
            <InputTextField
              disabled
              value={data?.product_name}
              heading="สินค้า"
            />
          </div>
          <div className="pt-2">
            <Row>
              <Col>
                <InputTextField
                  disabled
                  value={formatRoundPayment(data?.round_payment)}
                  heading="รอบการออม"
                />
              </Col>
              <Col>
                <InputTextField
                  disabled
                  value={data?.time_payment}
                  heading="ระยะเวลาออม (เดือน)"
                />
              </Col>
            </Row>
          </div>
          <div className="pt-2">
            <InputTextField
              disabled
              value={numberFormat(data?.round_amount, 0, 0)}
              heading="ยอดออมต่อรอบ"
            />
          </div>
          <div className="text-center pt-4">
            <ImageStyle src={data?.product_img} onError={onImgError} />
          </div>
          <div className="pt-4">
            <ButtonCustom
              mode={"default"}
              btnStyle={{ width: "100%" }}
              textButton={"ถัดไป"}
              onClick={() => {
                props.onClickNext && props.onClickNext();
              }}
            />
          </div>

        </div>) : (<div>
          <div className="pt-2">
            <InputTextField
              value={name}
              heading="ชื่อ"
              onchange={(e: any) => {
                if (checkThaiLang(e.target.value)) {
                  setName(e.target.value);
                  setErrorMessage({ ...errorMessage, NAME: "" });
                } else if (e.target.value === '') {
                  setName('')
                }

              }}
              helperText={errorMessage.NAME}
              required
            />
          </div>
          <div className="pt-2">
            <InputTextField
              value={surname}
              heading="นามสกุล"
              onchange={(e: any) => {
                if (checkThaiLang(e.target.value)) {
                  setSurname(e.target.value);
                  setErrorMessage({ ...errorMessage, SURNAME: "" });
                } else if (e.target.value === '') {
                  setSurname('')
                }

              }}
              helperText={errorMessage.SURNAME}
              required
            />
          </div>
          <div className="pt-2">
            <InputTextField
              value={idCard}
              type='number'
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              heading="เลขบัตรประชาชน"
              onchange={(e: any) => {
                if (e.target.value.length <= 13) {
                  setIdCard(e.target.value);
                  setErrorMessage({ ...errorMessage, IDCARD: "" });
                }

              }}
              helperText={errorMessage.IDCARD}
              required
            />
          </div>
          <div className="pt-2">
            <InputTextField
              value={promotion}
              heading="รหัสโปรโมชั่น (ถ้ามี)"
              onchange={(e: any) => {
                if (checkEngLang(e.target.value) && e.target.value.length <= 15) {
                  setPromotion(e.target.value);
                } else if (e.target.value === '') {
                  setPromotion('')
                }
              }}
            />
          </div>
          <div className="pt-4">
            <ButtonCustom
              mode={"default"}
              btnStyle={{ width: "100%" }}
              textButton={"ถัดไป"}
              onClick={() => {
                handleNextStep();
              }}
            />
          </div>
        </div>)}
    </div>
  );
}
