import liff from "@line/liff";
import { Check, ContentCopy } from "@mui/icons-material";
import { Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import AuthenApi from '../../api/authen/authen.api';
import ButtonCustom from "../../components/button/buttonCustom";
import { swalError, swalSuccess } from "../../components/notification/swal";
import UploadImage from "../../components/upload/uploadFile";
import { colors } from "../../constants/colors";
import { imgs } from "../../constants/images";
import { getToken } from "../../utils/app.utils";
import * as Styles from "./upload-slip.style";

import _ from "lodash";
import { useHistory } from "react-router-dom";
import StorageUploadApi from "../../api/storage/storage.api";
import PaymentUploadApi from "../../api/payment/payment.api";
import Swal from "sweetalert2";
export default function UploadSlip() {
  const [firstName, setFirstName] = useState("");
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [file, setFile] = useState<any>();
  const [loadingImg, setLoadingImg] = useState<boolean>(false);
  const [activeBtn, setActiveBtn] = useState<boolean>(true);
  const copyOriginal = `0791298954`;
  const history = useHistory();


  const loadCurrentUser = async () => {
    try {
      const result = await AuthenApi.getUser();
      if (result.status === 200) {
        setFirstName(result?.data?.data?.getCurrent?.fullName);
      }
      if (result.data.errors) {
        Swal.fire({
          icon: "warning",
          title: "อัพโหลดสลิป",
          text: "ระบบไม่พบข้อมูลผู้ใช้ กรุณาเข้าสู่ระบบก่อนค่ะ",
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          liff.closeWindow();
        })
      }
    } catch (error) {
      console.log("loadCurrentUser : ", error);
      swalError("loadCurrentUser : " + error);
    }
  }

  useEffect(() => {
    setLoadingImg(false); // true เปิด progress bar
    loadCurrentUser();
  }, []);

  const handleCopy = () => {
    setIsCopy(true);
    navigator.clipboard.writeText(copyOriginal);
    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  const onUpload = (event: any) => {
    if (event.target.files[0]) {
      // ไฟล์รูปภาพเพื่อแสดง
      setImage(URL.createObjectURL(event.target.files[0]));
    }

    if (event.target.files[0]) {
      // ไฟล์จริงใน state ที่นำไปบันทึก
      setFile(event.target.files[0]);
      setActiveBtn(false);
    }
  };

  const onsubmit = async () => {
    liff.init({ liffId: '2005464105-zLqAy8DJ', withLoginOnExternalBrowser: true }).then(() => {
      if (!liff.isInClient()) return swalError("line liff ผิดพลาด");
    })
    
    if (image !== "") {
      setActiveBtn(true);
      setLoadingImg(true);
      try {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("bucket", '2');
        const res = await StorageUploadApi.uploadSlipFile(formData)
        if (res.status === 200) {
          const result = await PaymentUploadApi.paymentUploadSlipFile({ slip: res.data?.url[0]?.url || "" })
          if (result.status === 200) {
            setActiveBtn(false);
            setImage("");
            setLoadingImg(false);
            Swal.fire({
              icon: "success",
              title: "อัพโหลดสลิปเรียบร้อย",
              text: "แอดมินจะทำการตรวจสอบและยืนยันสลิปอีกครั้ง",
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              liff.closeWindow();
            })
          } else {
            swalError("ระบบมีปัญหากรุณาลองใหม่อีกครั้งค่ะ");
            setActiveBtn(true);
            liff.closeWindow();
          }
        } else {
          swalError("ระบบมีปัญหากรุณาลองใหม่อีกครั้งค่ะ");
          setActiveBtn(true);
          liff.closeWindow();
        }

      } catch (error: any) {
        console.error("Error upload slip : ", error);
        setLoadingImg(false);
        // swalError("upload slip : " + String(error?.response?.data?.message));
        swalError("กรุณาอัพโหลดสลิป ขนาดรูปภาพไม่เกิน 2MB");
        setActiveBtn(false);
      }
    } else {
      swalError("กรุณาเลือกรูปภาพ!");
    }
  };



  return (
    <div className="px-3 py-2">
      <div className="d-flex justify-content-center">
        <Typography fontSize={20} style={{ color: colors.themeMainColor }}>
          สวัสดีคุณ
        </Typography>
        <Typography fontSize={20} style={{ marginLeft: "2px" }}>{firstName}</Typography>
      </div>
      <div className="d-flex justify-content-center">
        <Typography fontSize={12} style={{ color: colors.danger }}>*กรุณาชำระเงินยอดขั้นต่ำ 50 บาท</Typography>
      </div>
      <div className="pt-2" style={{ marginTop: "-20px" }}>
        <Styles.Snippet mb={4} mt={2} maxWidth={700} mx={"auto"}>
          <div className="d-flex justify-content-start">
            <div style={{ marginTop: "5px" }}>
              <img className="logo-upload-slip" src={imgs.logoKbank} width={"100%"} />
            </div>

            <div style={{ marginLeft: "13px" }}>
              <Typography>ธนาคาร กสิกรไทย</Typography>
              <Typography>ชื่อบัญชี บจก. ดี แอพเมคเกอร์</Typography>
              <Typography style={{ color: colors.themeThirdColor }}>
                079-1-29895-4
              </Typography>
            </div>
          </div>
          <div>
            <ButtonCustom
              mode={"default"}
              startIcon={isCopy ? <Check /> : <ContentCopy />}
              textButton="คัดลอก"
              onClick={() => {
                handleCopy();
              }}
              style={{ justifyContent: "end", height: 30 }}
            />
          </div>
        </Styles.Snippet>

        <Styles.Snippet
          mb={4}
          mt={2}
          maxWidth={700}
          mx={"auto"}
          className="text-center"
        >
          <UploadImage
            preview={image}
            loading={loadingImg}
            helperText={"กรุณาอัพโหลดไฟล์รูปภาพเท่านั้น"}
            onUpload={(event: any) => {
              onUpload(event);
            }}
          />
        </Styles.Snippet>
        <ButtonCustom
          mode={"default"}
          textButton="ยืนยัน"
          onClick={() => {
            onsubmit();
          }}
          style={{ width: "100%" }}
          disabled={activeBtn}
        // disabled={_.isEmpty(image)}
        />
      </div>
    </div>
  );
}
