import BaseAPI from '../api'

// const path = 'https://api.shibaphone.com/aomdown/app/masterdata'
const path = '/aomdown/app/masterdata'

export default class MasterDataApi extends BaseAPI {

  static getTermsAndConditions(): Promise<any> {
    return this.api.get(`${path}/getTermsAndConditons`).then((res) => res)
  }

  static getProduct(param: any): Promise<any> {
    return this.api.get(`${path}/product`, { params: { ...param } }).then((res) => res)
  }

  static getProductDetail(param: any): Promise<any> {
    return this.api.get(`${path}/product-detail`, { params: { ...param } }).then((res) => res)
  }

}
