import liff from "@line/liff";
import Download from '@mui/icons-material/FileDownloadOutlined';
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AomdownApi from "../../../../api/aomdown/aomdown.api";
import ButtonCustom from "../../../../components/button/buttonCustom";
import InputTextField from "../../../../components/input/inputTextField";
import Loading from "../../../../components/loading/loading";
import { swalError } from "../../../../components/notification/swal";
import { colors } from "../../../../constants/colors";
import { onImgError } from "../../../../constants/images";
import { numberFormat } from "../../../../utils/common";
import { decryptString } from "../../../../utils/crypto-js";

const ImageStyle = styled(`img`)(({ theme }) => ({
    width: 180,
    height: 180,

    [theme.breakpoints.down("lg")]: {
        width: 170,
        height: 170,
    },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: colors.disabledLightGray
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: colors.themeMainColor,
    },
}));


export default function AomdownDetail() {
    const [data, setData] = useState<any>();
    const [loadingPage, setLoadingPage] = useState(false);

    const formatRoundPayment = (key: string) => {
        switch (key) {
            case 'DAY':
                return 'รายวัน'
            case 'WEEK':
                return 'รายสัปดาห์'
            case 'MONTH':
                return 'รายเดือน'
            default:
                break;
        }
    }

    useEffect(() => {
        getAomdownDetail()
    }, [])

    useEffect(() => {
        liff.init({ liffId: '2005464105-VglK7OM1' })
    }, []);
    const getAomdownFileDownload = async (downPaymentNo: string) => {
        try {
            setLoadingPage(true);
            const res = await AomdownApi.getAomdownPDF(downPaymentNo)
            if (res.status == 200) {
                console.log('url', res.data)
                liff.openWindow(
                    {
                        url: res.data.data,
                        external: true
                    }
                )
            }
            setLoadingPage(false);
        } catch (error) {
            setLoadingPage(false);
            console.error('Error downloading file:', error);
        }
    };

    const getAomdownDetail = async () => {
        try {
            setLoadingPage(true)
            const res = await AomdownApi.getAomdownDetail();
            if (res.status == 200) {
                const aomdownDetail = res?.data?.data
                if (aomdownDetail) {
                    const product_down = aomdownDetail?.product_down_payment?.product
                    const data = {
                        downpayment_no: decryptString(aomdownDetail?.downpayment_no),
                        register_date: aomdownDetail?.created_date,
                        name: decryptString(aomdownDetail?.user_info?.general?.fullname),
                        idCard: decryptString(aomdownDetail?.id_card),
                        product_name: product_down?.text,
                        aomdown_price: aomdownDetail?.amount,
                        round_payment: aomdownDetail?.round_payment,
                        time_payment: aomdownDetail?.time_payment,
                        round_amount: aomdownDetail?.round_amount,
                        pdfContract: aomdownDetail?.pdfContract,
                        paid_amount: aomdownDetail?.paid_amount,
                        paid_rate: aomdownDetail?.paid_rate,
                        img: aomdownDetail?.product_img
                    }
                    setData(data)
                }
            } else {
                swalError(String(res?.data?.message));
            }
            setLoadingPage(false)
        } catch (error: any) {
            setLoadingPage(false)
            swalError(String('เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
            console.error(error)
        }
    }

    return (
        <div>
            <Loading show={loadingPage} />
            <div className="pt-2">
                <Row>
                    <Col>
                        <InputTextField
                            disabled
                            value={data?.register_date}
                            heading="วันที่ลงทะเบียน"
                        />
                    </Col>
                    <Col>
                        <div>สัญญาออมดาวน์</div>
                        <ButtonCustom
                            mode={'default'}
                            btnStyle={{ width: '100%', height: 43 }}
                            startIcon={<Download />}
                            textButton={'ดาวน์โหลด'}
                            onClick={() => {
                                if (data?.downpayment_no) getAomdownFileDownload(data?.downpayment_no)
                            }}
                        />
                    </Col>
                </Row>
            </div>
            <div className="pt-2">
                <InputTextField
                    disabled
                    value={`${data?.name || ''}`}
                    heading="ชื่อ-นามสกุล"
                />
            </div>
            <div className="pt-2">
                <InputTextField
                    disabled
                    value={data?.idCard || ''}
                    heading="เลขบัตรประชาชน"
                />
            </div>
            <div className="pt-2">
                <InputTextField
                    disabled
                    value={data?.product_name || ''}
                    heading="สินค้า"
                />
            </div>
            <div className="pt-2">
                <Row>
                    <Col>
                        <InputTextField
                            disabled
                            value={formatRoundPayment(data?.round_payment) || ''}
                            heading="รอบการออม"
                        />
                    </Col>
                    <Col>
                        <InputTextField
                            disabled
                            value={data?.time_payment || ''}
                            heading="ระยะเวลาออม (เดือน)"
                        />
                    </Col>
                </Row>
            </div>
            <div className="pt-2">
                <InputTextField
                    disabled
                    value={numberFormat(data?.round_amount, 0, 0)}
                    heading="ยอดออมต่อรอบ"
                />
            </div>
            <div className="pt-2">
                <div className="pb-2 d-flex justify-content-between">
                    <div>
                        ชำระแล้ว
                    </div>
                    <div>
                        ยอดออม
                    </div>
                </div>
                <BorderLinearProgress variant="determinate" value={data?.paid_rate} />
                <div className="pt-2 d-flex justify-content-between">
                    <div>
                        {numberFormat(data?.paid_amount || 0)}
                    </div>
                    <div>
                        {numberFormat(data?.aomdown_price || 0)}
                    </div>
                </div>
            </div>
            <div className="text-center pt-4">
                <ImageStyle className="" src={data?.img} onError={onImgError} />
            </div>
        </div>
    )
}