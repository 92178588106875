import liff from "@line/liff";
import { Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import AuthenApi from "../../../api/authen/authen.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import InputTextField from "../../../components/input/inputTextField";
import Loading from "../../../components/loading/loading";
import { swalError, swalSuccess } from "../../../components/notification/swal";
import { setClientKey, setRefreshToken, setToken } from "../../../utils/app.utils";
import { encryptString } from "../../../utils/crypto-js";
import OtpApi from "../../../api/otp/otp.api";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import { useHistory } from "react-router-dom";
import { routeName } from "../../../routes/routers-name";
import { colors } from "../../../constants/colors";
import { checkPattenPhoneNumber } from "../../../utils/common";

const initStateErrorMessage = {
    REGISTER_MOBILE: ``,
};

export default function Login() {
    const [loadingPage, setLoadingPage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
    const [otp, setOtp] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [confirmOtp, setConfirmOtp] = useState<boolean>(false);
    const [msgError, setMsgError] = useState<string>("");

    const [countdown, setCountdown] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [accTokenLine, setAccTokenLine] = useState<any>('');
    const [checkPhoneNumber, setCheckPhonenumber] = useState<boolean>(false)
    const [checkDigiPhoneNumber, setCheckDigiPhonenumber] = useState<boolean>(false)
    const history = useHistory();

    useEffect(() => {
        liff.init({ liffId: '2005464105-zLqAy8DJ', withLoginOnExternalBrowser: true })
            .then(async () => {
                if (liff.isLoggedIn()) {
                    const token = await liff.getAccessToken()
                    setAccTokenLine(token)
                } else {
                    liff.login();
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    useEffect(() => {
        if (phoneNumber && phoneNumber.length === 10) {
            const check = checkPattenPhoneNumber(phoneNumber)
            setCheckPhonenumber(!check)
            setCheckDigiPhonenumber(false)
        }
    }, [phoneNumber]);

    const handleChange = (newValue: string) => {
        setOtp(newValue);
        sendVerifyOtpAuto(newValue);
    };

    const sendVerifyOtpAuto = async (numberOtp: string) => {
        const isNumeric = /^\d+$/.test(numberOtp.trim());
        if (!isNumeric) {
            console.log("OTP must contain only numbers.");
            return;
        }
        if (numberOtp.length === 6) {
            login(numberOtp);
        }
    };

    const login = async (numberOtp: string) => {
        console.log('Login')
        setLoadingPage(true); //
        try {
            const encryptPhoneNumber = encryptString(phoneNumber);
            const encryptOtp = encryptString(numberOtp);
            console.log('encryptPhoneNumber', encryptPhoneNumber);
            console.log('encryptOtp', encryptOtp);
            const payload = {
                phoneNumber: encryptPhoneNumber,
                otp: encryptOtp,
                accessTokenLine: accTokenLine,
                // accessTokenLine: "eyJhbGciOiJIUzI1NiJ9.xOTbX5mkF3K7aE9v0Amg7kyhmuwM462FwmEkW3tPY2UpO0cj8cN9RQgKCr5kOBYkfoQ8wR6OX4JQz9CE6MNDoL0not75MVnCHBV9Rli_fV7hXVlR50c6ziW7--RIikVOJ4JjKaMJ08zna9hil59-ppUAZU26M_8M72thzsZY9ag.0LcMmxYdWdKfD3Oz1gyzU0MVlrvG8QYM-YzlymO4fFo",
            };
            const res = await AuthenApi.login(payload);
            if (res.status === 200) {
                console.log('res userlogin', res)
                if (res.data.data.userLogin.message === "OTP_OVER_VERIFY") {
                    swalError(`กรอกรหัส OTP ผิดเกินกำหนด ไม่สามารถทำรายการได้!`);
                    setPhoneNumber('');
                    setLoadingPage(false);
                    setConfirmOtp(false);
                    return
                }
                if (res.data.data.userLogin.message === "REGISTER") {
                    swalError(`ไม่พบข้อมูลค่ะ กรุณาลงทะเบียนก่อน เข้าสู่ระบบ นะคะ`);
                    setPhoneNumber('');
                    setLoadingPage(false);
                    setConfirmOtp(false);
                    return
                }
                if (res.data.data.userLogin.message === "OTP_NOT_FOUND") {
                    swalError(`รหัส OTP ผิดพลาด!`);
                    setPhoneNumber('');
                    setLoadingPage(false);
                    setConfirmOtp(false);
                    return
                }
                // swalSuccess(`เข้าสู่ระบบสำเร็จ`);
                setToken(res.data.data.userLogin.accessToken || '');
                setRefreshToken(res.data.data.userLogin.refreshToken || '');
                setClientKey(res.data.data.userLogin.clientKey || '')
                const regisByAdmin = await checkAdminRegister(res.data.data.userLogin.accessToken)
                if (regisByAdmin) {
                    setLoadingPage(false);
                    return history.push(routeName.registerAomDown, { state: true })
                } else {
                    setLoadingPage(false);
                    await AuthenApi.verify(accTokenLine)
                    liff.closeWindow();
                }

            }
            setLoadingPage(false);
        } catch (error: any) {
            setPhoneNumber('');
            setLoadingPage(false);
            setConfirmOtp(false)
            setOtp('')
            console.log(error);
            swalError(error.response.data.message);
            // swalError(`login error: ${error}`);
        } finally {
            setOtp('')
        }
    };

    const checkAdminRegister = async (token: string) => {
        try {
            const res = await AomdownApi.getAdminRegisterNewToken(token);
            if (res.status === 200) {
                const data = res.data.data;
                if (data) {
                    return true
                } else {
                    return false
                }
            }
        } catch (error: any) {
            swalError(String('เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
            return false

        }
    };

    const requestOtpTimeout = async (e: any) => {
        e.preventDefault();
        try {
            setOtp("");
            e.preventDefault();
            const encryptPhoneNumber = encryptString(phoneNumber);
            const res = await OtpApi.otpRequest({ tel: encryptPhoneNumber, page: "login" });
            if (res.status === 200) {
                setIsButtonDisabled(false);
                setCountdown(30);
                setMsgError("");
            }
        } catch (error: any) {
            swalError(String(error?.response.data.message));
            setMsgError("");
            setIsButtonDisabled(true);
            console.error("There was an error making the request:", error);
        }
    };

    const requestOtp = async (e: any) => {
        // try {
        //     if (checkPhoneNumber || checkDigiPhoneNumber || phoneNumber.length !== 10) {
        //         const msg: any = {
        //             REGISTER_MOBILE: !phoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง' : phoneNumber.length !== 10 ? 'ระบุจำนวน 10 ตัวอักษร' : '',
        //         }
        //         return setErrorMessage({ ...initStateErrorMessage, ...msg })
        //     }
        //     setIsButtonDisabled(true);
        //     const encryptPhoneNumber = encryptString(phoneNumber);
        //     setLoadingPage(true);
        //     const res = await OtpApi.otpRequest({ tel: encryptPhoneNumber });
        //     if (res.status === 200) {
        //         setConfirmOtp(true);
        //     } else {
        //         setConfirmOtp(false);
        //     }
        //     setLoadingPage(false);
        // } catch (error) {
        //     setLoadingPage(false);
        // }
        if (checkPhoneNumber || checkDigiPhoneNumber || phoneNumber.length !== 10) {
            const msg: any = {
                REGISTER_MOBILE: !phoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง' : phoneNumber.length !== 10 ? 'ระบุจำนวน 10 ตัวอักษร' : '',
            }
            return setErrorMessage({ ...initStateErrorMessage, ...msg })
        }
        setIsButtonDisabled(true);
        e.preventDefault();
        try {
            const encryptPhoneNumber = encryptString(phoneNumber);
            console.log("number otp : ", encryptPhoneNumber);
            setLoadingPage(true);
            const res = await OtpApi.otpRequest({ tel: encryptPhoneNumber, page: "login" });
            console.log('res request otp', res);
            if (res.status === 200) {
                if (res.data.message === "OTP-OVER-VERIFY") {
                    swalError("กรอกรหัส OTP ผิดเกินกำหนด ไม่สามารถทำรายการได้!");
                    setLoadingPage(false);
                    return;
                }
                setConfirmOtp(true);
            }
            setLoadingPage(false);
        } catch (error: any) {
            setLoadingPage(false);
            swalError(String(error?.response.data.message));
            setConfirmOtp(false);
            console.error("There was an error making the request:", error);
            // ต้องย้อนกลับไปหน้า กรอกเบอร์โทรศัพท์ยืนยัน OTP
            setConfirmOtp(false);
            setOtp("");
        }
    };

    return (
        <div className="px-3 py-3">
            <div>
                <Loading show={loadingPage}></Loading>
                {!confirmOtp && (
                    <>
                        {/* <Typography>กรอกเบอร์โทรศัพท์เพื่อรับรหัส OTP</Typography> */}
                        <div className="font-20 fw-bold">
                            <InputTextField
                                type="number"
                                value={phoneNumber}
                                heading="กรอกเบอร์โทรศัพท์เพื่อรับรหัส OTP"
                                size="medium"
                                headingColor={colors.warning}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                onchange={(e: any) => {
                                    if (e.target.value.length <= 10) {
                                        setMsgError("");
                                        setPhoneNumber(e.target.value);
                                        setErrorMessage({ ...errorMessage, REGISTER_MOBILE: '' })
                                    }
                                }}
                                required
                                helperText={errorMessage.REGISTER_MOBILE}
                            />
                        </div>
                        <div className="pt-4">
                            <ButtonCustom
                                mode={"default"}
                                btnStyle={{ width: "50%" }}
                                textButton={"ส่งรหัส OTP"}
                                onClick={(e) => {
                                    requestOtp(e);
                                }}
                            />
                        </div>
                    </>
                )}

                {confirmOtp && (
                    <>
                        <Typography className="text-center fw-bold font-18" style={{ color: colors.warning, fontSize: '20px' }}>
                            รหัสถูกส่งไปที่ SMS บนโทรศัพท์ของท่าน
                        </Typography>
                        <MuiOtpInput TextFieldsProps={{
                            type: 'number',
                            inputProps: {
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                            },
                        }} value={otp || ""} length={6} onChange={handleChange} />
                        <div className="f-14 request-otp" style={{ marginTop: '5px' }}>
                            {isButtonDisabled ? (
                                <a className="otp-link" style={{ color: colors.warning }} onClick={(e) => requestOtpTimeout(e)}>
                                    กดเพื่อส่งรหัส OTP ใหม่อีกครั้ง
                                </a>
                            ) : (
                                <a className="otp-link-enabled">
                                    กรุณารอทำรายการใหม่อีกครั้งใน {countdown} วินาที
                                </a>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}