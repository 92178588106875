
import BaseAPIUpload from '../api-upload'

const path = '/aomdown/app/authen'

export default class AuthenUploadApi extends BaseAPIUpload {

    static registerUpload(payload: any): Promise<any> {
        return this.api.post(`${path}/register-upload`, payload).then((res) => res)
    }
}